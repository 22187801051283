import 'pure-react-carousel/dist/react-carousel.es.css';

import cx from 'classnames';
import { graphql } from 'gatsby';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import React from 'react';
import { connect } from 'react-redux';

import {
  FaqList,
  Icon,
  OverflowText,
  TextParser,
  TourGallery,
  TourHeroPrivate,
  TourReviews,
  FlybusSchedule,
  SreInfo,
} from '../components';
import CancellationPolicy from '../components/CancellationPolicy';
import { Desktop, Image, Link, Mobile } from '../components/index';
import * as wysiwygStyles from '../elements/PageWysiwyg/PageWysiwyg.module.scss';
import withMeta from '../hocs/withMeta';
import { ThirdParty } from '../icons';
import {
  ICurrencyOption,
  IPageContext,
  IProduct,
  IReducers,
  ITourDetails,
  ITourItem,
  ITransport,
  ITranslationsPageTourDescription,
  IFlybus,
  ICurrencyIcons,
  ICart,
} from '../interfaces';
import { chunkTours, formatPrice } from '../utils';
import * as styles from './styles/tourDetails.module.scss';
import {
  changeAnnouncementVisible,
  changeAnnouncementVisibleMobile,
  changeIcelandair,
} from '../redux/actions/layoutActions';
import { changeCurrency } from '../redux/actions/currencyActions';
import { changeLocale } from '../redux/actions/translationActions';

interface IProps {
  data: {
    contentfulTourPrivate: ITourDetails;
    contentfulTranslationsPage: ITranslationsPageTourDescription;
    allContentfulTranslationsVarious: any;
  };
  pageContext: IPageContext;
  currency: ICurrencyOption;
  lang: string;
  tourDescriptionNotAvailable: string;
  tourDescriptionLoading: string;
  flybusData: IFlybus;
  changeIcelandair(parameter: boolean): void;
  changeAnnouncementVisible(parameter: boolean): void;
  changeAnnouncementVisibleMobile(parameter: boolean): void;
  changeDismiss(parameter: boolean): void;
  currencyDropdown: ICurrencyIcons;
  changeCurrency(newCurrency: ICurrencyOption, cartCount: number): void;
  cartObject: ICart | null;
  changeLocale(locale: string, lang: string): void;
  langaugeSwithcerData: ILangOptions[];
  isIcelandair: boolean;
  location: {
    pathname: string;
    search: string;
    hash: string;
    origin: string;
  };
}
interface IState {
  product: IProduct | null;
  transport: ITransport | null;
  loading: boolean;
  productYML: IDefaultPrices[] | null;
  loadingYML: boolean;
  flybus: IFlybus | null;
  slicedSlugIcelandair: string;
  slicedSlug: string;
}

interface IDefaultPrices {
  defaultPrice: number | null;
  discountPrice: number | null | undefined;
  id: number;
}

interface ILangOptions {
  iconName: string;
  text: string;
  locale: string;
  lang: string;
  langPath: string;
}
const BOX_HEIGHT = 250;

class Tour extends React.Component<IProps, IState> {
  readonly state: IState = {
    product: null,
    transport: null,
    loading: true,
    productYML: [],
    loadingYML: false,
    flybus: null,
    slicedSlugIcelandair: '',
    slicedSlug: '',
  };

  schedule: HTMLDivElement | null = null;
  main: HTMLDivElement | null = null;
  info: HTMLDivElement | null = null;
  gallery: HTMLDivElement | null = null;
  reviews: HTMLDivElement | null = null;
  map: HTMLDivElement | null = null;
  faq: HTMLDivElement | null = null;
  wysiwyg: HTMLDivElement | null = null;
  moreTours: HTMLDivElement | null = null;

  componentDidMount() {
    this.setState({ loading: false });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      productId: this.props.data.contentfulTourPrivate.productNumber,
    });
  }

  componentDidUpdate(prevProps: IProps) {}

  render() {
    const { data } = this.props;
    const { contentfulTourPrivate } = data;

    const { product, loading } = this.state;

    return (
      <div>
        <TourHeroPrivate
          tour={contentfulTourPrivate}
          product={product}
          productLoaded={!loading}
          productId={contentfulTourPrivate.productCode}
        />

        {this.renderBody()}
      </div>
    );
  }
  renderImage(tour: ITourItem, loading: boolean) {
    return (
      <div className={styles.imageContainer}>
        <Image
          fluidImage={tour.heroImage}
          // productPhoto={productPhoto}
          loadingProduct={loading}
          productPhotoSize='large'
          className={styles.image}
        />
        {tour.offerBanner ? (
          <div className={styles.specialOfferBanner}>
            <strong>{tour.offerBanner.title}</strong>
          </div>
        ) : null}
      </div>
    );
  }

  renderMobileImage(tour: ITourItem, loading: boolean) {
    return (
      <div className={styles.mobileImageContainer}>
        <Image
          fluidImage={tour.heroImage}
          // productPhoto={product && product.KeyPhoto}
          loadingProduct={loading}
          productPhotoSize='large'
          className={styles.imageMobile}
        />
        {tour.offerBanner ? (
          <div className={styles.specialOfferBanner}>
            <strong>{tour.offerBanner.title}</strong>
          </div>
        ) : null}
      </div>
    );
  }

  returnDefaultPrice = (productId: any) => {
    let defaultPrice = null;
    if (this.state.productYML) {
      for (const productYML of this.state.productYML) {
        if (productYML.id.toString() === productId.toString()) {
          defaultPrice = productYML.defaultPrice;
          break;
        }
      }
    }
    return defaultPrice;
  };

  returnDiscountPrice = (productId: any) => {
    let discountPrice = null;
    if (this.state.productYML) {
      for (const productYML of this.state.productYML) {
        if (productYML.id.toString() === productId.toString()) {
          if (productYML.discountPrice) {
            discountPrice = productYML.discountPrice;
          }
          break;
        }
      }
    }
    return discountPrice;
  };

  getPrice(tour: ITourItem) {
    const { productId } = tour;
    const { tourDescriptionLoading } =
      this.props.data.contentfulTranslationsPage;
    const price = this.returnDefaultPrice(productId);
    const discountPrice = this.returnDiscountPrice(productId);
    if (this.state.loadingYML) {
      return <p className={styles.price}>{tourDescriptionLoading}</p>;
    } else {
      if (!!discountPrice && !!price) {
        return (
          <div className={styles.priceContainer}>
            <p className={styles.originalPrice}>
              {formatPrice(price, this.props.currency.value)}{' '}
              {this.props.currency.label}
            </p>
            <p className={styles.offerPrice}>
              {formatPrice(discountPrice, this.props.currency.value)}{' '}
              {this.props.currency.label}
            </p>
          </div>
        );
      } else {
        return price ? (
          <div className={styles.priceContainer}>
            <p className={styles.price}>
              {formatPrice(price, this.props.currency.value)}{' '}
              {this.props.currency.label}
            </p>
          </div>
        ) : null;
      }
    }
  }

  renderTourYML(tour: any, mobile: boolean, sliderOrNot: string) {
    let desk = '';
    if (sliderOrNot === 'slider') {
      desk = 'is-3-desktop';
    } else if (sliderOrNot === 'inidividual') {
      desk = 'is-half-desktop';
    } else {
      desk = 'is-6 is-6-desktop';
    }
    const langPath = this.props.pageContext.langPath;
    const link = `${tour.pathPrefix}/${tour.slug}`;
    const loading = false;
    return (
      <div
        key={tour.id}
        className={
          mobile
            ? cx('column', 'is-half ', styles.tourItem)
            : cx('column', desk, styles.tourItem)
        }
      >
        <Link langPath={langPath} to={link} className={styles.tourItemLink}>
          {mobile
            ? this.renderMobileImage(tour, loading)
            : this.renderImage(tour, loading)}
          <div className={styles.card}>
            {tour.category && tour.category.length > 0 && (
              <p className={styles.category}>
                {tour.category[0].title.toUpperCase()}
              </p>
            )}
            <p className={styles.title}>{tour.title}</p>

            {this.getPrice(tour)}
          </div>
        </Link>
      </div>
    );
  }

  slides(slidesChunk: any, deskOrMobile: string) {
    if (deskOrMobile === 'desktop') {
      return (
        <Slider>
          {slidesChunk
            ? slidesChunk.map((value: any, index: number) => {
                return (
                  <Slide index={index} key={index}>
                    <div
                      className={cx(
                        'columns',
                        'is-multiline',
                        styles.container
                      )}
                    >
                      {this.slidesHelper(value, deskOrMobile)}
                    </div>
                  </Slide>
                );
              })
            : null}
        </Slider>
      );
    } else {
      return (
        <Slider className={styles.sliderMobile}>
          {slidesChunk
            ? slidesChunk.map((value: any, index: number) => {
                return (
                  <Slide index={index} key={index}>
                    <div
                      className={cx(
                        'columns',
                        'is-multiline',
                        'is-variable',
                        'is-2',
                        'is-mobile',
                        styles.container
                      )}
                    >
                      {this.slidesHelper(value, deskOrMobile)}
                    </div>
                  </Slide>
                );
              })
            : null}
        </Slider>
      );
    }
  }

  slidesHelper(element: any, deskOrMobile: string) {
    const item: any = [];
    let deskMobileBool = true;
    if (deskOrMobile === 'desktop') {
      deskMobileBool = false;
    } else {
      deskMobileBool = true;
    }
    element.forEach((i: any) => {
      {
        item.push(this.renderTourYML(i, deskMobileBool, 'slider'));
      }
    });
    return item;
  }

  // TODO previous and next text span
  renderMoreToursYML() {
    const { contentfulTourPrivate } = this.props.data;
    const { tours } = contentfulTourPrivate.wysiwyg?.tours ? contentfulTourPrivate.wysiwyg : contentfulTourPrivate;
    const toursChunk = chunkTours(tours, 4);
    const toursChunkMobile = chunkTours(tours, 2);
    return (
      <React.Fragment>
        <Mobile>
          {toursChunkMobile.length <= 1 ? (
            <div
              className={cx(
                'columns',
                'is-multiline',
                'is-variable',
                'is-2',
                'is-mobile',
                styles.container
              )}
            >
              {tours.map((tour: any) =>
                this.renderTourYML(tour, true, 'not-slider')
              )}
            </div>
          ) : null}
          {toursChunkMobile.length ? (
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={50}
              totalSlides={toursChunkMobile.length}
              dragEnabled={false}
              infinite={true}
            >
              <div className={styles.containerCarousel}>
                {this.slides(toursChunkMobile, 'mobile')}
                {toursChunkMobile.length > 1 ? (
                  <ButtonBack className={styles.buttonBack}>
                    <>
                      <Icon name='ARROWLEFT' />
                      <span>Previous</span>
                    </>
                  </ButtonBack>
                ) : null}
                {toursChunkMobile.length > 1 ? (
                  <ButtonNext className={styles.buttonNext}>
                    <>
                      <Icon name='ARROWRIGHT' />
                      <span>Next</span>
                    </>
                  </ButtonNext>
                ) : null}
              </div>
            </CarouselProvider>
          ) : null}
        </Mobile>
        <Desktop>
          <div className={cx('columns', 'is-multiline', 'centered-content')}>
            {toursChunk.length === 1
              ? tours.map((tour: any) =>
                  this.renderTourYML(tour, false, 'not-slider')
                )
              : null}
          </div>
          {toursChunk.length > 1 ? (
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={50}
              totalSlides={toursChunk.length}
              dragEnabled={false}
              infinite={true}
            >
              <div className={cx(styles.containerCarousel, 'centered-content')}>
                {this.slides(toursChunk, 'desktop')}
                <ButtonBack className={styles.buttonBack}>
                  <>
                    <Icon name='ARROWLEFT' />
                    <span>Previous</span>
                  </>
                </ButtonBack>
                <ButtonNext className={styles.buttonNext}>
                  <>
                    <Icon name='ARROWRIGHT' />
                    <span>Next</span>
                  </>
                </ButtonNext>
              </div>
            </CarouselProvider>
          ) : null}
        </Desktop>
      </React.Fragment>
    );
  }

  renderBody() {
    const { contentfulTourPrivate, contentfulTranslationsPage, allContentfulTranslationsVarious } = this.props.data;
    const { reviews, faq, flybusSchedule, wysiwyg } = contentfulTourPrivate;
    const { faq: faqText } = contentfulTranslationsPage;
    const { product, transport } = this.state;
    const highlights = this.renderHighlights();
    const included = this.renderIncluded();
    const thirdParty = this.renderThirdParty();
    const tours = wysiwyg?.tours ?? this.props.data.contentfulTourPrivate.tours;

    return (
      <div
        style={{ marginBottom: 80, marginTop: this.state.transport ? 50 : 0 }}
      >
        {flybusSchedule && (
          <div className={'centered-content'} style={{ marginBottom: 100 }}>
            <FlybusSchedule {...flybusSchedule} />
          </div>
        )}

        {wysiwyg && (included || highlights) && (
          <>
            <div
              className='centered-content vertical-margin'
              ref={(elem) => (this.wysiwyg = elem)}
            >
              <h2 className='element-title'>{wysiwyg.title}</h2>
              <TextParser
                source={wysiwyg.text.text}
                className={cx(wysiwygStyles.text, {
                  [wysiwygStyles.twoColumns]: wysiwyg.numberOfColumns === 2,
                })}
              />
            </div>
          </>
        )}

        {(included || highlights) && (
          <div className={'centered-content'}>
            <div className={styles.moreInfo} ref={(elem) => (this.info = elem)}>
              <div className='columns'>
                {highlights}
                {included}
              </div>
              <div className='columns'>{thirdParty}</div>
              <SreInfo
                product={product}
                sreInfo={contentfulTranslationsPage.sreInfo}
              />
              <div className='columns'>
                {product && (
                  <div className='column'>
                    <CancellationPolicy
                      penaltyRules={product.CancellationPolicy.penaltyRules}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {this.renderImageGallery()}

        {reviews && (
          <div
            ref={(elem) => (this.reviews = elem)}
            className='vertical-margin'
          >
            <TourReviews reviews={reviews} />
          </div>
        )}

        {faq && (
          <div
            className={'centered-content vertical-margin'}
            ref={(elem) => (this.faq = elem)}
          >
            <h2 className='element-title'>{faqText}</h2>
            <FaqList faq={faq} />
          </div>
        )}

        {wysiwyg && !included && !highlights && (
            <div
              className='centered-content vertical-margin'
              ref={(elem) => (this.wysiwyg = elem)}
            >
              <h2 className='element-title'>{wysiwyg.title}</h2>
              <TextParser
                source={wysiwyg.text.text}
                className={cx(wysiwygStyles.text, {
                  [wysiwygStyles.twoColumns]: wysiwyg.numberOfColumns === 2,
                })}
              />
            </div>
        )}

        {tours ? (
          <div
            className='centered-content'
            ref={(elem) => (this.moreTours = elem)}
          >
            <div>
              <h2>{allContentfulTranslationsVarious?.edges[0]?.node?.relatedGroupTours.relatedGroupTours }</h2>{' '}
            </div>

            {this.renderMoreToursYML()}
          </div>
        ) : null}
        {!!transport && transport.CancellationPolicy.penaltyRules.length > 0 ? (
          <div className='centered-content'>
            <div className='columns'>
              <div className='column'>
                <CancellationPolicy
                  penaltyRules={transport.CancellationPolicy.penaltyRules}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderHighlights() {
    const { wysiwyg, tourHighlights, tourHighlightsTitle } =
      this.props.data.contentfulTourPrivate;
    const { tourDescriptionTourHighlights } =
      this.props.data.contentfulTranslationsPage;

    const highlightsText =
      wysiwyg?.tourHighlights?.tourHighlights ?? tourHighlights;

    if (!highlightsText) {
      return null;
    }

    return (
      <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
        <div className={styles.infoBox}>
          <div className={styles.infoLine} />
          <h2 className={styles.navText}>
            {tourHighlightsTitle || tourDescriptionTourHighlights}
          </h2>
          <OverflowText
            text={highlightsText.toString()}
            textClassName={styles.highlights}
            height={BOX_HEIGHT}
          />
        </div>
      </div>
    );
  }

  calculateCutOffHour(hour: number) {
    const {
      tourDescriptionCancellationPolicyDays: days,
      tourDescriptionCancellationPolicyHours: hours,
    } = this.props.data.contentfulTranslationsPage;
    let result = null;
    if (hour <= 48) {
      result = hour + ' hours';
    } else {
      const Days = Math.floor(hour / 24);
      const Remainder = hour % 24;
      const Hours = Math.floor(Remainder);
      result = Days + ' ' + days + ' ';
      if (Hours > 0) {
        result += Hours + ' ' + hours + ' ';
      }
    }
    return result;
  }

  
  renderThirdParty() {
    const { thirdPartyInfo } = this.props.data.contentfulTranslationsPage;
    const { allContentfulTranslationsVarious } = this.props.data;

    let thirdPartyInfoText;

    if (this.props.lang == "EN") {
      thirdPartyInfoText = allContentfulTranslationsVarious?.edges[0]?.node?.thirdPartyInfoPrivate?.thirdPartyInfoPrivate || thirdPartyInfo;
    }
    else{
      thirdPartyInfoText = allContentfulTranslationsVarious?.edges[1]?.node?.thirdPartyInfoPrivate?.thirdPartyInfoPrivate || thirdPartyInfo;
    }   

    return (
      <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
        <div className={styles.infoBox} style={{ padding: '15px 25px' }}>
          <div className={styles.infoLine} style={{ marginBottom: '5px' }} />
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <img
              src={ThirdParty}
              alt='ThirdParty'
              style={{ padding: '5px', width: '3em' }}
            />
            <div className={styles.thirdParty} style={{ alignSelf: 'center' }}>
              {thirdPartyInfoText}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderIncluded() {
    const { wysiwyg, included, needToKnow } =
      this.props.data.contentfulTourPrivate;
    const {
      tourDescriptionLoading,
      tourDescriptionNeedToKnow,
      tourDescriptionWhatsIncluded,
    } = this.props.data.contentfulTranslationsPage;

    const { loading, product } = this.state;
    let includedText;
    let needToKnowText;

    const privateTourIncluded = wysiwyg?.included ?? included;

    if (privateTourIncluded) {
      includedText = privateTourIncluded.included;
    } else if (
      product &&
      product.Inclusions &&
      product.Inclusions.Description
    ) {
      includedText = product.Inclusions.Description;
    }

    if (needToKnow) {
      needToKnowText = needToKnow.needToKnow;
    } else if (
      product &&
      product.NeedToKnow &&
      product.NeedToKnow.Description
    ) {
      needToKnowText = product.NeedToKnow.Description;
    }

    if (!includedText && !needToKnowText) {
      if (loading) {
        includedText = tourDescriptionLoading;
      } else {
        return null;
      }
    }

    let text = includedText || '';
    text += includedText && needToKnowText ? '\n' : '';
    text += needToKnowText || '';

    let title =
      tourDescriptionWhatsIncluded + ' & ' + tourDescriptionNeedToKnow;
    if (includedText && !needToKnowText) {
      title = tourDescriptionWhatsIncluded;
    } else if (needToKnowText && !includedText) {
      title = tourDescriptionNeedToKnow;
    }

    return (
      <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
        <div className={styles.infoBox}>
          <div className={styles.infoLine} />
          <h2 className={styles.navText}>{title}</h2>
          <OverflowText
            text={text}
            textClassName={styles.includes}
            height={BOX_HEIGHT}
          />
        </div>
      </div>
    );
  }

  renderImageGallery() {
    const { wysiwyg, images } = this.props.data.contentfulTourPrivate;

    const imageGallery = wysiwyg?.images ?? images;

    if (imageGallery && imageGallery.length > 0) {
      return (
        <div ref={(elem) => (this.gallery = elem)}>
          <TourGallery images={imageGallery} />
        </div>
      );
    }
    return null;
  }
}

export const query = graphql`
  query TourQueryPrivate($slug: String!, $locale: String!) {
    contentfulTourPrivate(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      tourId
      productCode
      priceFrom
      id
      slug
      minimumAge
      duration
      heroImage {
        ...ImageInfo
        fluid(quality: 60, maxHeight: 400) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      category {
        ...TourCategory
      }
      season
      difficulty
      language
      wysiwyg {
        ...PageWysiwyg
      }
      privateTourUrl
    }
    contentfulTranslationsPage(node_locale: { eq: $locale }) {
      tourInfoTransportPriceFrom
      tourDescriptionCancellationPolicyDays
      tourDescriptionCancellationPolicyHeader
      tourDescriptionCancellationPolicyHours
      tourDescriptionCancellationPolicyText
      tourDescriptionClose
      tourDescriptionLoading
      tourDescriptionNotAvailable
      tourDescriptionNeedToKnow
      tourDescriptionTourHighlights
      tourDescriptionWhatsIncluded
      faq
      moreToursYouMightLikeHeader
      thirdPartyInfo
      sreInfo
    }
    allContentfulTranslationsVarious {
      edges {
        node {
          headerlanguageSwitcher {
            iconName
            text
            locale
            lang
            langPath
          }
          thirdPartyInfoPrivate {
            thirdPartyInfoPrivate
          }
          relatedGroupTours {
            relatedGroupTours
          }
        }
      }
    }
  }
`;

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang } = reducers.translationReducers;
  const { isIcelandair, announcementVisible, announcementVisibleMobile } =
    reducers.layoutReducer;
  return {
    currency,
    lang,
    isIcelandair,
    announcementVisible,
    announcementVisibleMobile,
  };
};

export default connect(mapStateToProps, {
  changeIcelandair,
  changeAnnouncementVisibleMobile,
  changeAnnouncementVisible,
  changeCurrency,
  changeLocale,
})(withMeta(Tour));
